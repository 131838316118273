<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      short-title="ZS"
      :title="storeName"
      :url="url"
      >
      <template slot-scope="props" slot="links">
        <div v-if="myGroup == 'Administrator' || myGroup == 'Staff'">
          <sidebar-item :link="{name: $t('dashboard'), icon: 'tim-icons icon-chart-bar-32', path: '/dashboard'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Search'), icon: 'tim-icons icon-zoom-split', path: '/system/search'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Products'), icon: 'tim-icons icon-bag-16'}">
            <sidebar-item :link="{name: $t('Products'), path: '/products/dashboard'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Types'), path: '/products/types'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Packages'), path: '/products/packages'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Coupons'), path: '/products/coupons'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{name: $t('Clients'), icon: 'tim-icons icon-single-02', path: '/clients/dashboard'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Orders'), icon: 'tim-icons icon-cart'}">
            <sidebar-item :link="{name: $t('New Orders'), path: '/orders/dashboardNew'}"></sidebar-item>
            <sidebar-item :link="{name: $t('To Details Orders'), path: '/orders/dashboardToDetails'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Processing Orders'), path: '/orders/dashboardProcessing'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Pending Orders'), path: '/orders/dashboardPending'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Canceled Orders'), path: '/orders/dashboardCanceled'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Active Orders'), path: '/orders/dashboardActive'}"></sidebar-item>
            <sidebar-item :link="{name: $t('To Stop Orders'), path: '/orders/dashboardToStop'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Stopped Orders'), path: '/orders/dashboardStopped'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{name: $t('Payments'), icon: 'tim-icons icon-coins'}">
            <sidebar-item :link="{name: $t('New Payments'), path: '/payments/dashboardNew'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Check Payments'), path: '/payments/dashboardCheck'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Paid Payments'), path: '/payments/dashboardPaid'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Pending Payments'), path: '/payments/dashboardPending'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Declined Payments'), path: '/payments/dashboardDeclined'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Chargeback Payments'), path: '/payments/dashboardChargeback'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Error Payments'), path: '/payments/dashboardError'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Canceled Payments'), path: '/payments/dashboardCanceled'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{name: $t('Reviews'), icon: 'tim-icons icon-satisfied', path: '/reviews/dashboard'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Requests'), icon: 'tim-icons icon-send', path: '/requests/dashboard'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Accounting'), icon: 'tim-icons icon-money-coins'}">
            <sidebar-item :link="{name: $t('dashboard'), path: '/accounting/dashboard'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Reports'), path: '/accounting/reports'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{name: $t('System'), icon: 'tim-icons icon-settings'}">
            <sidebar-item :link="{name: $t('Announcements'), path: '/system/announcements'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Settings'), path: '/system/settings'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Users'), path: '/system/users'}"></sidebar-item>
          </sidebar-item>
        </div>

        <div v-if="myGroup == 'Client' || myGroup == 'Reseller'">
          <sidebar-item :link="{name: $t('dashboard'), icon: 'tim-icons icon-chart-bar-32', path: '/dashboard'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Products'), icon: 'tim-icons icon-bag-16', path: '/my/products'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Orders'), icon: 'tim-icons icon-cart', path: '/my/orders'}"></sidebar-item>
          <!-- <sidebar-item :link="{name: $t('ordersDetails'), icon: 'tim-icons icon-attach-87', path: '/my/details'}"></sidebar-item> -->
          <sidebar-item :link="{name: $t('Payments'), icon: 'tim-icons icon-coins', path: '/my/payments'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Reviews'), icon: 'tim-icons icon-satisfied', path: '/my/reviews'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Requests'), icon: 'tim-icons icon-send', path: '/my/requests'}"></sidebar-item>
        </div>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from './SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      myGroup: '',
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  computed:{
    ...mapGetters([
      'getResources'
    ]),
    storeName() {
      return this.getResources.store;
    },
    url() {
      return this.getResources.url;
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
    this.$http.get('profile')
    .then(response => response.json())
    .then(response => {
        this.myGroup = response.type;
    })
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
