import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// resource
import VueResource from 'vue-resource';
import { store } from '@/store/store.js';

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueResource);
Vue.http.options.root = "api/";

router.beforeEach((to, from, next) => { // https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
  if(localStorage.getItem('token') == null &&
    to.path != '/login' &&
    to.path != '/register' &&
    to.path != '/registered' &&
    to.path != '/confirmation' &&
    to.path != '/reset' &&
    to.path != '/logout'
  ){
    next('/login');
  }else{
    Vue.http.interceptors.push((request, next) => {
      request.headers.set('Authorization', localStorage.getItem('token'))
      request.headers.set('Accept', 'application/json')
    });
    next();
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router,
  i18n
});
