<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <base-button native-type="button" type="primary" size="sm" block @click="$router.go(-1)">
          <i class="tim-icons icon-minimal-left"></i>
      </base-button>
      <a class="navbar-brand" href="#">{{ $t(routeName) }}</a>
    </div>
    
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <base-dropdown
        v-if="cart.length > 0"
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
        >
        <template
          slot="title"
        >
          <i class="tim-icons icon-cart"></i>
          <p class="d-lg-none">Cart</p>
        </template>
      </base-dropdown> -->
      <base-input>
        <el-select
          v-model="myLang"
          class="select-primary"
          @change="changeLang"
        >
          <el-option
            v-for="option in langs"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
      </base-input>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
        >
        <template
          slot="title"
        >
          <!-- <div v-if="isNew" class="notification d-none d-lg-block d-xl-block"></div> -->
          <i @click="newNotifications = false" class="tim-icons icon-bell-55" :style="newNotifications ? 'color: red;':null"></i>
          <p class="d-lg-none">{{ $t('New Notifications') }}</p>
        </template>
        <li
          v-for="(notification, index) in notifications"
          class="nav-link nav-item dropdown-item"
          :key="index"
          >
            {{ notification.text }}
        </li>
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
        >
        <template
          slot="title"
        >
          <div class="photo">
            <img v-if="resources.me.image" :src="'users/'+resources.me.image" />
            <img v-else src="img/default-avatar.png" />
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">{{ $t('Me') }}</p>
        </template>
        <li class="nav-link">
          <a href="#/profile" class="nav-item dropdown-item">{{ $t('Profile') }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#/logout" class="nav-item dropdown-item">{{ $t('Logout') }}</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { mapState } from 'vuex'
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { Select, Option } from 'element-ui';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
    [Option.name]: Option,
    [Select.name]: Select
  },
  computed: {
    ...mapState([
      'resources',
      // 'cart'
    ]),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    isNew() {
      return this.newNotifications;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      notifications: [],
      newNotifications: false,
      langs: [
        {id: 'en', name: 'English'},
        {id: 'nl', name: 'Nederlands'},
        {id: 'fr', name: 'Française'},
        {id: 'es', name: 'Español'},
        {id: 'ar', name: 'العربية'},
      ],
      myLang: 'en'
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    updateNotifications() {
      this.$http.get('notifications')
      .then( response => response.json())
      .then( response => {
        this.notifications=[];
        response.forEach(element => {
          if(element.status == 'New') this.newNotifications = true;
          this.notifications.unshift(element);
        });
      })
    },
    changeLang(){
      this.$i18n.locale = this.myLang;
      localStorage.setItem('lang', this.myLang);
      // if(this.myLang == 'ar'){
      //   this.$rtl.enableRTL();
      // }else{
      //   this.$rtl.disableRTL();
      // }
    }
  },
  created(){
    this.myLang = (localStorage.getItem('lang'))? localStorage.getItem('lang'):'en';
    this.changeLang();
    this.updateNotifications();
    setInterval(() => { this.updateNotifications() }, 10000);
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
