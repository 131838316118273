import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');

// Pages
const Login = () => import(/* webpackChunkName: "pages" */ 'src/pages/Login.vue');
const Register = () => import(/* webpackChunkName: "pages" */ 'src/pages/Register.vue');
const Registered = () => import(/* webpackChunkName: "pages" */ 'src/pages/Registered.vue');
const Confirmation = () => import(/* webpackChunkName: "pages" */ 'src/pages/Confirmation.vue');
const Reset = () => import(/* webpackChunkName: "pages" */ 'src/pages/Reset.vue');


let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login
    },
    {
      path: 'register',
      name: 'Register',
      component: Register
    },
    {
      path: 'registered',
      name: 'Registered',
      component: Registered
    },
    {
      path: 'confirmation',
      name: 'Confirmation',
      component: Confirmation
    },
    {
      path: 'reset',
      name: 'Reset',
      component: Reset
    }
  ]
};

// Products
const productsDashboard = () => import(/* webpackChunkName: "products" */ 'src/pages/products/dashboard.vue');
const productsAdd = () => import(/* webpackChunkName: "products" */ 'src/pages/products/add.vue');
const productsEdit = () => import(/* webpackChunkName: "products" */ 'src/pages/products/edit.vue');
const types = () => import(/* webpackChunkName: "products" */ 'src/pages/products/types.vue');
const typeAdd = () => import(/* webpackChunkName: "products" */ 'src/pages/products/typeAdd.vue');
const typeEdit = () => import(/* webpackChunkName: "products" */ 'src/pages/products/typeEdit.vue');
const packages = () => import(/* webpackChunkName: "products" */ 'src/pages/products/packages.vue');
const packageAdd = () => import(/* webpackChunkName: "products" */ 'src/pages/products/packageAdd.vue');
const packageEdit = () => import(/* webpackChunkName: "products" */ 'src/pages/products/packageEdit.vue');
const coupons = () => import(/* webpackChunkName: "products" */ 'src/pages/products/coupons.vue');
const couponAdd = () => import(/* webpackChunkName: "products" */ 'src/pages/products/couponAdd.vue');
const couponEdit = () => import(/* webpackChunkName: "products" */ 'src/pages/products/couponEdit.vue');

let productsMenu = {
  path: '/products',
  component: DashboardLayout,
  name: 'Products',
  redirect: '/products/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'Products Dashboard',
      components: { default: productsDashboard }
    },
    {
      path: 'add',
      name: 'Products Add',
      components: { default: productsAdd }
    },
    {
      path: 'edit',
      name: 'Products Edit',
      components: { default: productsEdit }
    },
    {
      path: 'types',
      name: 'Products Types',
      components: { default: types }
    },
    {
      path: 'typeAdd',
      name: 'Products Type Add',
      components: { default: typeAdd }
    },
    {
      path: 'typeEdit',
      name: 'Products Type Edit',
      components: { default: typeEdit }
    },
    {
      path: 'packages',
      name: 'Products Packages',
      components: { default: packages }
    },
    {
      path: 'packageAdd',
      name: 'Products Package Add',
      components: { default: packageAdd }
    },
    {
      path: 'packageEdit',
      name: 'Products Package Edit',
      components: { default: packageEdit }
    },
    {
      path: 'coupons',
      name: 'Products Coupons',
      components: { default: coupons }
    },
    {
      path: 'couponAdd',
      name: 'Products Coupon Add',
      components: { default: couponAdd }
    },
    {
      path: 'couponEdit',
      name: 'Products Coupon Edit',
      components: { default: couponEdit }
    }
  ]
};

// Clients
const clientsDashboard = () => import(/* webpackChunkName: "clients" */ 'src/pages/clients/dashboard.vue');
const clientsOrders = () => import(/* webpackChunkName: "clients" */ 'src/pages/clients/orders.vue');

let clientsMenu = {
  path: '/clients',
  component: DashboardLayout,
  name: 'Clients',
  redirect: '/clients/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'Clients Dashboard',
      components: { default: clientsDashboard }
    },
    {
      path: 'orders',
      name: 'Client Orders',
      components: { default: clientsOrders }
    }
  ]
};

// Orders
const ordersDashboardNew = () => import('src/pages/orders/dashboardNew.vue');
const ordersDashboardProcessing = () => import('src/pages/orders/dashboardProcessing.vue');
const ordersDashboardPending = () => import('src/pages/orders/dashboardPending.vue');
const ordersDashboardCanceled = () => import('src/pages/orders/dashboardCanceled.vue');
const ordersDashboardActive = () => import('src/pages/orders/dashboardActive.vue');
const ordersDashboardStopped = () => import('src/pages/orders/dashboardStopped.vue');
const ordersDashboardToStop = () => import('src/pages/orders/dashboardToStop.vue');
const ordersDashboardToDetails = () => import('src/pages/orders/dashboardToDetails.vue');
const orderDetails = () => import('src/pages/orders/details.vue');
const ordersEdit = () => import('src/pages/orders/edit.vue');
const orderActivation = () => import('src/pages/orders/activation.vue');

let ordersMenu = {
  path: '/orders',
  component: DashboardLayout,
  name: 'Orders',
  redirect: '/orders/dashboardNew',
  children: [
    {
      path: 'dashboardNew',
      name: 'New Orders Dashboard',
      components: { default: ordersDashboardNew }
    },
    {
      path: 'dashboardProcessing',
      name: 'Processing Orders Dashboard',
      components: { default: ordersDashboardProcessing }
    },
    {
      path: 'dashboardPending',
      name: 'Pending Orders Dashboard',
      components: { default: ordersDashboardPending }
    },
    {
      path: 'dashboardCanceled',
      name: 'Canceled Orders Dashboard',
      components: { default: ordersDashboardCanceled }
    },
    {
      path: 'dashboardActive',
      name: 'Active Orders Dashboard',
      components: { default: ordersDashboardActive }
    },
    {
      path: 'dashboardStopped',
      name: 'Stopped Orders Dashboard',
      components: { default: ordersDashboardStopped }
    },
    {
      path: 'dashboardToStop',
      name: 'To Stop Orders Dashboard',
      components: { default: ordersDashboardToStop }
    },
    {
      path: 'dashboardToDetails',
      name: 'To Details Orders Dashboard',
      components: { default: ordersDashboardToDetails }
    },
    {
      path: 'details',
      name: 'Order Details',
      components: { default: orderDetails }
    },
    {
      path: 'edit',
      name: 'Orders Edit',
      components: { default: ordersEdit }
    },
    {
      path: 'activation',
      name: 'Order Activation',
      components: { default: orderActivation }
    }
  ]
};

// Payments
const paymentsDashboardNew = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardNew.vue');
const paymentsDashboardCheck = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardCheck.vue');
const paymentsDashboardPaid = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardPaid.vue');
const paymentsDashboardPending = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardPending.vue');
const paymentsDashboardDeclined = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardDeclined.vue');
const paymentsDashboardChargeback = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardChargeback.vue');
const paymentsDashboardError = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardError.vue');
const paymentsDashboardCanceled = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/dashboardCanceled.vue');
const paymentDetails = () => import(/* webpackChunkName: "payments" */ 'src/pages/payments/details.vue');

let paymentsMenu = {
  path: "/payments",
  component: DashboardLayout,
  name: "Payments",
  redirect: "/payments/dashboardNew",
  children: [
    {
      path: "dashboardNew",
      name: "New Payments Dashboard",
      components: { default: paymentsDashboardNew }
    },
    {
      path: "dashboardCheck",
      name: "Check Payments Dashboard",
      components: { default: paymentsDashboardCheck }
    },
    {
      path: "dashboardPaid",
      name: "Paid Payments Dashboard",
      components: { default: paymentsDashboardPaid }
    },
    {
      path: "dashboardPending",
      name: "Pending Payments Dashboard",
      components: { default: paymentsDashboardPending }
    },
    {
      path: "dashboardDeclined",
      name: "Declined Payments Dashboard",
      components: { default: paymentsDashboardDeclined }
    },
    {
      path: "dashboardChargeback",
      name: "Chargeback Payments Dashboard",
      components: { default: paymentsDashboardChargeback }
    },
    {
      path: "dashboardError",
      name: "Error Payments Dashboard",
      components: { default: paymentsDashboardError }
    },
    {
      path: "dashboardCanceled",
      name: "Canceled Payments Dashboard",
      components: { default: paymentsDashboardCanceled }
    },
    {
      path: "details",
      name: "Payment Details",
      components: { default: paymentDetails }
    }
  ]
};

// Reviews
const reviewsDashboard = () => import(/* webpackChunkName: "reviews" */ 'src/pages/reviews/dashboard.vue');

let reviewsMenu = {
  path: '/reviews',
  component: DashboardLayout,
  name: 'Reviews',
  redirect: '/reviews/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'Reviews Dashboard',
      components: { default: reviewsDashboard }
    }
  ]
};

// Requests
const requestsDashboard = () => import(/* webpackChunkName: "reviews" */ 'src/pages/requests/dashboard.vue');

let requestsMenu = {
  path: '/requests',
  component: DashboardLayout,
  name: 'Requests',
  redirect: '/requests/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'Requests Dashboard',
      components: { default: requestsDashboard }
    }
  ]
};

// Accounting
const accountingDashboard = () => import(/* webpackChunkName: "accounting" */ 'src/pages/accounting/dashboard.vue');
const accountingAdd = () => import(/* webpackChunkName: "accounting" */ 'src/pages/accounting/add.vue');
const accountingEdit = () => import(/* webpackChunkName: "accounting" */ 'src/pages/accounting/edit.vue');
const accountingReports = () => import(/* webpackChunkName: "accounting" */ 'src/pages/accounting/reports.vue');

let accountingMenu = {
  path: '/accounting',
  component: DashboardLayout,
  name: 'Accounting',
  redirect: '/accounting/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'Accounting Dashboard',
      components: { default: accountingDashboard }
    },
    {
      path: 'add',
      name: 'Accounting Add',
      components: { default: accountingAdd }
    },
    {
      path: 'edit',
      name: 'Accounting Edit',
      components: { default: accountingEdit }
    },
    {
      path: 'reports',
      name: 'Accounting Reports',
      components: { default: accountingReports }
    }
  ]
};

// System
const Announcements = () => import(/* webpackChunkName: "system" */ 'src/pages/system/announcements.vue');
const AnnouncementAdd = () => import(/* webpackChunkName: "system" */ 'src/pages/system/announcementAdd.vue');
const AnnouncementEdit = () => import(/* webpackChunkName: "system" */ 'src/pages/system/announcementEdit.vue');
const Settings = () => import(/* webpackChunkName: "system" */ 'src/pages/system/settings.vue');
const SettingsEdit = () => import(/* webpackChunkName: "system" */ 'src/pages/system/settingsEdit.vue');
const Users = () => import(/* webpackChunkName: "system" */ 'src/pages/system/users.vue');
const Add = () => import(/* webpackChunkName: "system" */ 'src/pages/system/add.vue');
const Edit = () => import(/* webpackChunkName: "system" */ 'src/pages/system/edit.vue');
const Search = () => import(/* webpackChunkName: "system" */ 'src/pages/system/search.vue');

let systemMenu = {
  path: '/system',
  component: DashboardLayout,
  name: 'System',
  redirect: '/system/settings',
  children: [
    {
      path: 'announcements',
      name: 'Announcements',
      components: { default: Announcements }
    },
    {
      path: 'announcementAdd',
      name: 'Announcement Add',
      components: { default: AnnouncementAdd }
    },
    {
      path: 'announcementEdit',
      name: 'Announcement Edit',
      components: { default: AnnouncementEdit }
    },
    {
      path: 'settings',
      name: 'System Settings',
      components: { default: Settings }
    },
    {
      path: 'settingsEdit',
      name: 'Settings Edit',
      components: { default: SettingsEdit }
    },
    {
      path: 'users',
      name: 'System Users',
      components: { default: Users }
    },
    {
      path: 'add',
      name: 'System Users Add',
      components: { default: Add }
    },
    {
      path: 'edit',
      name: 'System Users Edit',
      components: { default: Edit }
    },
    {
      path: 'search',
      name: 'Search',
      components: { default: Search }
    }
  ]
};


// My
const myDashboard = () => import(/* webpackChunkName: "my" */ 'src/pages/my/dashboard.vue');
const myProducts = () => import(/* webpackChunkName: "my" */ 'src/pages/my/products.vue');
const myReorder = () => import(/* webpackChunkName: "my" */ 'src/pages/my/reorder.vue');
const myOrders = () => import(/* webpackChunkName: "my" */ 'src/pages/my/orders.vue');
const myPaymentConfirmation = () => import(/* webpackChunkName: "my" */ 'src/pages/my/confirmation.vue');
const myOrderDetails = () => import(/* webpackChunkName: "my" */ "src/pages/my/details.vue");
const myPayments = () => import(/* webpackChunkName: "my" */ 'src/pages/my/payments.vue');
const myReviews = () => import(/* webpackChunkName: "my" */ 'src/pages/my/reviews.vue');
const myRequests = () => import(/* webpackChunkName: "my" */ 'src/pages/my/requests.vue');
const myPaypal = () => import(/* webpackChunkName: "my" */ 'src/pages/my/paypal.vue');
const myPayssion = () => import(/* webpackChunkName: "my" */ 'src/pages/my/payssion.vue');
const myPaymentwall = () => import(/* webpackChunkName: "my" */ 'src/pages/my/paymentwall.vue');
const myAfterOrder = () => import(/* webpackChunkName: "my" */ 'src/pages/my/afterorder.vue');
const myPaymentDetails = () => import(/* webpackChunkName: "my" */ 'src/pages/my/paymentDetails.vue');

let myMenu = {
  path: "/my",
  component: DashboardLayout,
  name: "My",
  redirect: "/my/dashboard",
  children: [
    {
      path: "dashboard",
      name: "My Dashboard",
      components: { default: myDashboard }
    },
    {
      path: "products",
      name: "My Products",
      components: { default: myProducts }
    },
    {
      path: "reorder",
      name: "Reorder",
      components: { default: myReorder }
    },
    {
      path: "orders",
      name: "My Orders",
      components: { default: myOrders }
    },
    {
      path: "details",
      name: "My Order Details",
      components: { default: myOrderDetails }
    },
    {
      path: "payments",
      name: "My Payments",
      components: { default: myPayments }
    },
    {
      path: "reviews",
      name: "My Reviews",
      components: { default: myReviews }
    },
    {
      path: "requests",
      name: "My Requests",
      components: { default: myRequests }
    },
    {
      path: "paypal",
      name: "My Paypal",
      components: { default: myPaypal }
    },
    {
      path: "payssion",
      name: "My Payment",
      components: { default: myPayssion }
    },
    {
      path: "paymentwall",
      name: "My Paymentwall",
      components: { default: myPaymentwall }
    },
    {
      path: "afterOrder",
      name: "My Payments & Orders confirmation",
      components: { default: myAfterOrder }
    },
    {
      path: "paymentDetails",
      name: "My Payment Details",
      components: { default: myPaymentDetails }
    },
    {
      path: "confirmation",
      name: "My Payment Confirmation",
      components: { default: myPaymentConfirmation }
    }
  ]
};

const Profile = () => import(/* webpackChunkName: "pages" */ 'src/pages/profile.vue');

const Logout = () => import(/* webpackChunkName: "pages" */ 'src/pages/logout.vue');

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  productsMenu,
  clientsMenu,
  ordersMenu,
  paymentsMenu,
  reviewsMenu,
  requestsMenu,
  accountingMenu,
  systemMenu,
  myMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      },
      {
        path: 'profile',
        name: 'Profile',
        components: { default: Profile }
      },
      {
        path: 'logout',
        name: 'Logout',
        components: { default: Logout }
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
