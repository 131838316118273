import Vue from 'vue'
import Vuex from 'vuex'
// import myAge from './Modules/age'
// import myName from './Modules/name';

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    resources: {
      me: {},
      products: [],
      pg: []
    },
    toEdit: 0,
    toDuplicate: 0,
    toDetails: 0,
    toOrders: 0,
    toReorder: null,
    toSearch: null
  },
  getters: {
    getResources(state) {
      return state.resources;
    }
  },
  mutations: {
    setResources(state, resources) {
      state.resources = resources;
    },
    setToEdit(state, id) {
      state.toEdit = id;
    },
    setToDuplicate(state, id) {
      state.toDuplicate = id;
    },
    setToDetails(state, id) {
      state.toDetails = id;
    },
    setToOrders(state, id) {
      state.toOrders = id;
    },
    setToReorder(state, id = null) {
      if(id){
        state.toReorder = id;
      }else{
        state.toReorder = null;
      }
    }
  },
  actions: {
    apiResources(context, payload = null) {
      Vue.http
      .get("resources")
      .then(response => response.json())
      .then(response => {
        context.commit("setResources", response);
      })
      .catch(response => {
        context.commit("setResources", null);
      });
    }
  },
  modules: {
    // myAge,
    // myName
  }
});